import React, {useEffect, useState} from 'react'
import { useAuth } from '../../context/AuthContext';
import moment from 'moment';
import { getAllEvents } from '../../api/users';
import {
  Button,
  Tabs,
  Table,
  Tag,
  Space,
  Row,
  Col
} from 'antd';
import { appointmentListColumn } from '../../components/Provider/appointmentListColumn';
import Loading from '../../components/Loading';
import axios from 'axios';

export default function AppointmentList() {
  const { token, currentUser, getAuthToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [appointmentList, setAppointmentList] = useState([]);
  const [allProvidersData, setAllProvidersData] = useState([]);

  useEffect(() => {
    async function fetchAllEvents() {
      if(!token) await getAuthToken(currentUser);
      getAppointmentList()
      getAllCoachesData()
    }
    fetchAllEvents()
  }, [])

  const getAppointmentList = () => {
    setLoading(true);
    let config = {
      method: 'get',
      url: process.env.REACT_APP_BACKEND_URL + 'admin/appointments',
      headers: {x_firebase_id: currentUser.uid, Authorization: 'JWT ' + token}
    }
    axios(config)
    .then(({data}) => {
      setAppointmentList(data)
      setLoading(false);
    }).catch(err => {
        console.log('error when fetching appointment list')
        setLoading(false);
    })
  }

  const getAllCoachesData = () => {
    axios({
        method: "get",
        url: process.env.REACT_APP_BACKEND_URL + "providers",
        headers: {
            x_firebase_id: currentUser.uid,
            Authorization: "JWT " + token,
            getallproviders: true,
        },
    }).then(({data}) => {
        let tempData = data.filter(obj => (!obj?.inactive)).map(provider => {
            if(!provider.inactive)
                return ({
                    text: provider?.first_name + ' ' + provider?.last_name,
                    value: provider?.firebase_id,
                })
        })
        setAllProvidersData(tempData);
    }).catch(err => {
    console.log("err in fetching assigned coaches", err);
    })
  };

  if (loading) {
    return (
      <Loading/>
    )
  }

  return (
    <div>
      <Table columns={appointmentListColumn(allProvidersData)} dataSource={appointmentList} bordered />
    </div>
  )
}
