import { Tag, Select, DatePicker, Input } from "antd";
import moment from "moment";
import { DeleteOutlined, SendOutlined } from "@ant-design/icons";

const { Option } = Select;

export const testEmailsColumn = (
  subjectInputTest,
  subjectInput,
  testTemplateInput,
  testEmailInput,
  emailTemplateInput,
  audienceInput,
  scheduleInput,
  handleSendEmail
) => {
  return [
    {
      title: "Email Template ID",
      dataIndex: "templateId",
      width: "20%",
      key: "emailTest",
      render: (templateId, record) => {
        if (record?.test) {
          return testTemplateInput;
        } else {
          return emailTemplateInput;
        }
      },
    },

    {
      title: "Email Subject",
      dataIndex: "templateSubject",
      width: "25%",
      key: "id2",
      render: (templateSubject, record) => {
        if (record?.test) {
          return subjectInputTest;
        } else {
          return subjectInput;
        }
      },
    },

    {
      title: "Audience",
      key: "audienceTest",
      dataIndex: "audience",
      width: "25%",
      render: (audience, record) => {
        if (record?.test) {
          return testEmailInput;
        } else {
          return audienceInput;
        }
      },
    },

    {
      title: "Schedule",
      dataIndex: "scheduleTime",
      key: "scheduleTimeTest",
      width: "20%",
      render: (scheduleTime) => {
        return scheduleInput;
      },
    },

    {
      title: "Send",
      dataIndex: "templateId",
      key: "sendTest",
      width: "10%",
      align: "center",
      render: (templateId, record) => {
        return (
          <SendOutlined
            style={{ color: "green", fontSize: "large" }}
            onClick={() => {
              handleSendEmail(record);
            }}
          />
        );
      },
    },
  ];
};

export const multiEmailsColumn = (handleDeleteEmail) => {
  return [
    {
      title: "Email Template ID",
      dataIndex: "templateId",
      width: "20%",
      key: "id2",
      render: (templateId, record) => {
        return <Input placeholder={templateId} disabled value={templateId} />;
      },
    },
    {
      title: "Email Subject",
      dataIndex: "templateSubject",
      width: "25%",
      key: "id2",
      render: (templateSubject, record) => {
        return <p>{templateSubject}</p>;
      },
    },

    {
      title: "Audience",
      key: "aud",
      dataIndex: "audience",
      width: "25%",
      render: (audience, record) => {
        let OptionList = [
          { value: "Basic", key: "basic" },
          { value: "Basic 7 days", key: "basic7" },
          { value: "Basic 30 days", key: "basic30" },
          { value: "Membership", key: "membership" },
          { value: "All Access", key: "premium" },
          { value: "All Users", key: "allusers" },
        ];

        return (
          <Select
            mode="multiple"
            disabled
            allowClear
            style={{ width: "100%" }}
            defaultValue={audience}
          >
            {OptionList.map((prog) => (
              <Option value={prog.key}>{prog.value}</Option>
            ))}
          </Select>
        );
      },
    },

    {
      title: "Scheduled Time",
      dataIndex: "scheduleTime",
      key: "time",
      width: "20%",
      render: (scheduleTime, record) => {
        return (
          <DatePicker showTime disabled defaultValue={moment(scheduleTime)} />
        );
      },
    },

    {
      title: "Delete",
      dataIndex: "templateId",
      key: "send",
      width: "10%",
      align: "center",
      render: (templateId, record) => {
        return (
          <DeleteOutlined
            style={{ color: "red", fontSize: "large" }}
            onClick={() => {
              handleDeleteEmail(templateId, record);
            }}
          />
        );
      },
    },
  ];
};
