import React, { useEffect, useState } from "react";
import { Button, Tabs, Table, Tag, Space, Row, Col, Input, Radio } from "antd";
import moment from "moment";
import Select from "react-select";
import { useAuth } from "../../context/AuthContext";
import {
  ReferenceLine,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { allColumns } from "../../components/Analytics/AnalyticsColumn";
import {
  allUsersColumn,
  paidUsersColumn,
} from "../../components/Analytics/columns";
import {
  getAllUsersBackEnd,
  getAllUserLoginTime,
  getAllData,
  getAllAppointmentData,
  getAllCoachesData
} from "../../api/users";
import StatusCard from "../../components/Analytics/StatusCard";
import Loading from "../../components/Loading";
import axios from "axios";
// import { groupBy } from "lodash";
import { db, superUsers } from "../../services/firebase";
import AnalyticsTable from "./AnalyticsTable";

const { TabPane } = Tabs;

export default function NewAnalytics() {
  const { token, currentUser, getAuthToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("3");
  const [activeDate, setActiveDate] = useState(moment());
  const [allUsersData, setAllUsersData] = useState(null);
  const [apptData, setApptData] = useState(null);

  const [paidUsersData, setPaidUserData] = useState([]);
  const [dailyActiveUsers, setDailyActiveUsers] = useState(0);
  const [monthlyActiveUsers, setMonthlyActiveUsers] = useState(0);
  const [analyticsTableData, setAnalyticsTableData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [selectedAppOptions, setSelectedAppOptions] = useState(null);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [providersList, setProvidersList] = useState(null);
  const [appointmentList, setAppointmentList] = useState([]);
  const [appointmentChartData, setAppointmentChartData] = useState([]);
  const [chartTimeline, setChartTimeline] = useState({
    label: "Daily",
    value: "daily",
  });
  const [appChartTimeline, setAppChartTimeline] = useState({
    label: "Daily",
    value: "day",
  });
  const [appCategory, setAppCategory] = useState([{ label: "All", value: "total_count" }]);
  const [appointmentAnalytics, setAppointmentAnalytics] = useState({
    weeklyAppointments: [],
    monthlyAppointments: [],
  });
  const [overAllData, setOverAllData] = useState({
    daily_no_of_new_subscribers: 0,
    daily_no_of_new_users: 0,
    monthly_no_of_new_subscribers: 0,
    monthly_no_of_new_users: 0,
    no_of_coaching: 0,
    no_of_subscribers: 0,
    daily_active_users: 0,
    monthly_active_users: 0,
    previous_week_no_of_new_users: 0,
    previous_2_week_no_of_new_users: 0,
  });
  const [searchEmail, setSearchEmail] = useState("");

  const fetchAllUsersData = async (selectedTab, selectedDate) => {
    setLoading(true);
    let parseDate = selectedDate ? selectedDate : activeDate;
    if (!token) await getAuthToken(currentUser);
    // getAppointmentAnalytics();
    // get all user data
    // const { data: backendUserData, error: backendDataError } =
    //   await getAllUsersBackEnd({ firebaseId: currentUser.uid, token: token });

    let temp = [];

    const { data: complete_data, error: error } = await getAllData({
      firebaseId: currentUser.uid,
      token: token,
      start: moment(parseDate).format("MM/DD/YYYY"),
      end: "",
    });

    // console.log(complete_data)

    prepareChartData(complete_data?.progress);
    setAllUsersData(complete_data);

    const { data: appt_data, error: err1 } = await getAllAppointmentData({
      firebaseId: currentUser.uid,
      token: token,
      start: moment(parseDate).format("MM/DD/YYYY"),
      end: "",
    });
    // console.log(appt_data)


    setApptData(appt_data);

    switch (selectedTab) {
      case "1":
        console.log("its daily");
        temp = [
          {
            key: 1,
            title: "Membership Users (Paid)",
            val: complete_data?.over_all?.daily_no_of_new_subscribers_mem,
          },
          {
            key: 2,
            title: "All- Access Users (Paid)",
            val: complete_data?.over_all?.daily_no_of_new_subscribers_premium,
          },
          {
            key: 3,
            title: "Medical Appointments",
            val: appt_data?.dailyNonCoaching?.length || 0,
          },
          {
            key: 4,
            title: "Coaching Appointments",
            val: appt_data?.dailyCoaching?.length || 0,
          },
          {
            key: 5,
            title: "Total Appointments",
            val: appt_data?.dailyBookedAppointments?.length || 0,
          },
          // {
          //     title: 'Rev-Appointments (Paid)',
          //     val: appt_data?.paidBookedAppointments,
          // },
          // {
          //     title: 'Non-Rev Appointments',
          //     val: appt_data?.paidBookedAppointments,
          // },
        ];

        break;
      case "2":
        console.log("its weekly");
        temp = [
          {
            key: 1,
            title: "Membership Users (Paid)",
            val: complete_data?.over_all?.weekly_no_of_new_subscribers_mem,
          },
          {
            key: 2,
            title: "All- access users (Paid)",
            val: complete_data?.over_all?.weekly_no_of_new_subscribers_premium,
          },
          {
            key: 3,
            title: "Medical Appointments",
            val: appt_data?.weeklyNonCoaching?.length || 0,
          },
          {
            key: 4,
            title: "Coaching Appointments",
            val: appt_data?.weeklyCoaching?.length || 0,
          },
          {
            key: 5,
            title: "Total Appointments",
            val: appt_data?.weeklyBookedAppointments?.length || 0,
          },

          // {
          //     title: 'Rev-Appointments (Paid)',
          //     val: appt_data?.paidBookedAppointments,
          // },
          // {
          //     title: 'Non-Rev Appointments',
          //     val: appt_data?.paidBookedAppointments,
          // },
        ];

        break;
      case "3":
        console.log("its monhtly");
        temp = [
          {
            key: 1,
            title: "Membership Users (Paid)",
            val: complete_data?.over_all?.monthly_no_of_new_subscribers_mem,
          },
          {
            key: 2,
            title: "All- access users (Paid)",
            val: complete_data?.over_all?.monthly_no_of_new_subscribers_premium,
          },
          {
            key: 3,
            title: "Medical Appointments",
            val: appt_data?.monthlyNonCoaching?.length || 0,
          },
          {
            key: 4,
            title: "Coaching Appointments",
            val: appt_data?.monthlyCoaching?.length || 0,
          },
          {
            key: 5,
            title: "Total Appointments",
            val: appt_data?.monthlyBookedAppointments?.length || 0,
          },

          // {
          //     title: 'Rev-Appointments (Paid)',
          //     val: appt_data?.paidBookedAppointments,
          // },
          // {
          //     title: 'Non-Rev Appointments',
          //     val: appt_data?.paidBookedAppointments,
          // },
        ];

        break;
      case "4":
        console.log("its yearly");
        temp = [
          {
            key: 1,
            title: "Membership Users (Paid)",
            val: complete_data?.over_all?.membership_no,
          },
          {
            key: 2,
            title: "All- access users (Paid)",
            val: complete_data?.over_all?.premium_no,
          },
          {
            key: 3,
            title: "Total appointments",
            val: appt_data?.monthlyBookedAppointments?.length || 0,
          },
          // {
          //     title: 'Rev-Appointments (Paid)',
          //     val: appt_data?.paidBookedAppointments,
          // },
          // {
          //     title: 'Non-Rev Appointments',
          //     val: appt_data?.paidBookedAppointments,
          // },
        ];

        break;
      default:
        temp = [
          {
            key: 1,
            title: "Membership Users (Paid)",
            val: complete_data?.over_all?.daily_no_of_new_subscribers_mem,
          },
          {
            key: 2,
            title: "All- Access Users (Paid)",
            val: complete_data?.over_all?.daily_no_of_new_subscribers_premium,
          },
          {
            key: 3,
            title: "Medical Appointments",
            val: appt_data?.dailyNonCoaching?.length || 0,
          },
          {
            key: 4,
            title: "Coaching Appointments",
            val: appt_data?.dailyCoaching?.length || 0,
          },
          {
            key: 5,
            title: "Total Appointments",
            val: appt_data?.dailyBookedAppointments?.length || 0,
          },
          // {
          //     title: 'Rev-Appointments (Paid)',
          //     val: appt_data?.paidBookedAppointments,
          // },
          // {
          //     title: 'Non-Rev Appointments',
          //     val: appt_data?.paidBookedAppointments,
          // },
        ];
    }
    setAnalyticsTableData(temp);

    // setSelectedTab();
    setLoading(false);
  };

  useEffect(() => {
    fetchProviders()
    getAppointmentList()
  }, [])

  const fetchProviders = async() => {
    const { data: allProviders } = await getAllCoachesData({token, firebaseId: currentUser.uid});
    // console.log('all providers: ', allProviders)
    setProvidersList(allProviders)
  }

  const getAppointmentList = () => {
    setLoading(true);
    let config = {
      method: 'get',
      url: process.env.REACT_APP_BACKEND_URL + 'admin/appointments',
      headers: {x_firebase_id: currentUser.uid, Authorization: 'JWT ' + token}
    }
    axios(config)
    .then(({data}) => {
      data.forEach((app) => {
        app.date = (app.appointmentStart);
        // console.log(app.date)
      });
      setAppointmentList(data)
    let tempArr = groupBy(data)
    tempArr.sort((a,b)=>new Date(a.date) - new Date(b.date));
    setAppointmentChartData(tempArr)
      setLoading(false);
    }).catch(err => {
        console.log('error when fetching appointment list')
        setLoading(false);
    })
  }

  useEffect(() => {
    let providers = selectedProviders.map(ele => ({ label: (ele.first_name + ' ' + ele.last_name), value: ele.firebase_id }))
    // console.log('providers: ', providers)
    setSelectedAppOptions(providers.concat(appCategory))
    let tempArr = groupBy(appointmentList)
    tempArr.sort((a,b)=>new Date(a.date) - new Date(b.date));
    setAppointmentChartData(tempArr)
  }, [selectedProviders, appChartTimeline, appCategory])

  const groupBy = (elements) => {
    const formatted = elements.map(elem => {
      return { date: moment(elem.date).startOf(appChartTimeline.value).format('YYYY-MM-DD'), total_count: 1, data: elem }
    })
    // console.log('formated dates: ', formatted)
    const dates = formatted.map(elem => elem.date)
    // console.log('dates: ', dates)
    const uniqueDates = dates.filter((date, index) => dates.indexOf(date) === index)
    // console.log('unique dates: ', uniqueDates)
    return uniqueDates.map(date => {
      const total_count = formatted.filter(elem => elem.date === date).reduce((total_count, elem) => total_count + elem.total_count, 0)
      const healthCoach = formatted.filter(elem => 
        elem.date === date && 
        (
          elem.data.providerData.user_type === 'Health Coach' ||
          elem.data.providerData.user_type === 'Dietitian'
        )
      ).reduce((total_count, elem) => total_count + elem.total_count, 0)
      const clinicians = formatted.filter(elem => 
        elem.date === date && 
        (
          elem.data.providerData.user_type !== 'Health Coach' &&
          elem.data.providerData.user_type !== 'Dietitian'
        )
      ).reduce((total_count, elem) => total_count + elem.total_count, 0)
      let providers = {}
      if(selectedProviders?.length) {
        selectedProviders.forEach(provider => {
          providers[provider.firebase_id] = formatted.filter(elem => 
            elem.date === date && elem.data.providerData.firebase_id === provider.firebase_id 
          ).reduce((total_count, elem) => total_count + elem.total_count, 0)
        })
      }

      let count_of_appointments = Object.assign({ date, total_count, healthCoach, clinicians }, providers)
      
      return count_of_appointments
    })
  }

  const prepareChartData = (data) => {
    if (!data || data.length === 0) {
      setLoading(false);
      return;
    }

    let chartData = [];
    data.forEach((each) => {
      chartData.unshift({
        newUsers: each.daily_no_of_new_users,
        activeUsers: each.daily_active_users,
        subscribers: each.daily_no_of_new_subscribers,
        monthly: each.monthly_no_of_new_users,
        date: moment(each.created_at).format("D-MMM-YY"),
        fullDate: each.created_at,
        totalUsers: each.total_no_of_users || 0,
      });
    });
    setChartData(chartData);
  };

  useEffect(() => {
    fetchAllUsersData("3");
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Row
        style={{ marginTop: 20, marginBottom: 20, justifyContent: "center" }}
      >
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Total Users"}
            content={allUsersData?.over_all?.total_no_of_users}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Membership Users"}
            content={allUsersData?.over_all?.membership_no}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Premium Users"}
            content={allUsersData?.over_all?.premium_no}
          />
        </Col>
        <Col xl={6} xs={24}>
          <StatusCard
            cardTitle={"Total Appointments"}
            content={apptData?.totalBookedAppointments}
          />
        </Col>
      </Row>

      <Tabs
        defaultActiveKey="3"
        activeKey={selectedTab}
        onTabClick={(activeKey) => {
          console.log(activeKey);
          setSelectedTab(activeKey);
          fetchAllUsersData(activeKey);
        }}
      >
        <TabPane tab="Daily" key="1">
          <Radio.Group
            defaultValue="b"
            buttonStyle="solid"
            style={{ marginTop: 16 }}
            onChange={(e) => {
              let val = e.target.value;
              if (val === "previous") {
                setActiveDate(moment(activeDate).subtract(1, "days"));
                fetchAllUsersData("1", moment(activeDate).subtract(1, "days"));
              } else {
                setActiveDate(moment(activeDate).add(1, "days"));
                fetchAllUsersData("1", moment(activeDate).add(1, "days"));
              }
            }}
          >
            <Radio.Button value="previous">Previous</Radio.Button>
            <Radio.Button value="current" disabled>
              {moment(activeDate).format("MM/DD/YYYY")}
            </Radio.Button>
            <Radio.Button value="next">Next</Radio.Button>
          </Radio.Group>
          <Table
            bordered
            columns={allColumns()}
            dataSource={analyticsTableData}
            scroll={{ x: "100%" }}
          />
        </TabPane>
        <TabPane tab="Weekly" key="2">
          <Radio.Group
            defaultValue="b"
            buttonStyle="solid"
            style={{ marginTop: 16 }}
            onChange={(e) => {
              let val = e.target.value;
              if (val === "previous") {
                setActiveDate(moment(activeDate).subtract(1, "week"));
                fetchAllUsersData("2", moment(activeDate).subtract(1, "week"));
              } else {
                setActiveDate(moment(activeDate).add(1, "week"));
                fetchAllUsersData("2", moment(activeDate).add(1, "week"));
              }
              // val === "previous"
              //   ? setActiveDate(moment(activeDate).subtract(1, "week"))
              //   : setActiveDate(moment(activeDate).add(1, "week"));
              // fetchAllUsersData("2");
            }}
          >
            <Radio.Button value="previous">Previous</Radio.Button>
            <Radio.Button value="current" disabled>
              {moment(activeDate).format("MM/DD/YYYY")}
            </Radio.Button>
            <Radio.Button value="next">Next</Radio.Button>
          </Radio.Group>
          <Table
            bordered
            columns={allColumns()}
            dataSource={analyticsTableData}
            scroll={{ x: "100%" }}
          />
        </TabPane>
        <TabPane tab="Monthly" key="3">
          <Radio.Group
            defaultValue="b"
            buttonStyle="solid"
            style={{ marginTop: 16 }}
            onChange={(e) => {
              let val = e.target.value;

              if (val === "previous") {
                setActiveDate(moment(activeDate).subtract(1, "months"));
                fetchAllUsersData(
                  "3",
                  moment(activeDate).subtract(1, "months")
                );
              } else {
                setActiveDate(moment(activeDate).add(1, "months"));
                fetchAllUsersData("3", moment(activeDate).add(1, "months"));
              }

              // val === "previous"
              //   ? setActiveDate(moment(activeDate).subtract(1, "months"))
              //   : setActiveDate(moment(activeDate).add(1, "months"));
              // fetchAllUsersData("3");
            }}
          >
            <Radio.Button value="previous">Previous</Radio.Button>
            <Radio.Button value="current" disabled>
              {moment(activeDate).format("MMMM 'YY")}
            </Radio.Button>
            <Radio.Button value="next">Next</Radio.Button>
          </Radio.Group>
          <Table
            bordered
            columns={allColumns()}
            dataSource={analyticsTableData}
            scroll={{ x: "100%" }}
          />
        </TabPane>
        {/* <TabPane tab="Yearly" key="4">
          <Radio.Group
            defaultValue="b"
            buttonStyle="solid"
            style={{ marginTop: 16 }}
            onChange={(e) => {
              let val = e.target.value;
              val === "previous"
                ? setActiveDate(moment(activeDate).subtract(1, "years"))
                : setActiveDate(moment(activeDate).add(1, "years"));
              fetchAllUsersData("4");
            }}
          >
            <Radio.Button value="previous">Previous</Radio.Button>
            <Radio.Button value="current" disabled>
              {moment(activeDate).format("MM/DD/YYYY")}
            </Radio.Button>
            <Radio.Button value="next">Next</Radio.Button>
          </Radio.Group>
          <Table
            bordered
            columns={allColumns()}
            dataSource={analyticsTableData}
            scroll={{ x: "100%" }}
          />
        </TabPane> */}
      </Tabs>

      <div
        style={{
          marginTop: 40,
          marginBottom: 40,
          padding: 40,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-evenly",
            marginBottom: 20,
          }}
        >
          <div style={{ width: "50%" }}>
            <Select
              isMulti={true}
              placeholder={"Select metric..."}
              options={[
                { label: "New Users", value: "newUsers" },
                { label: "Active Users", value: "activeUsers" },
                { label: "New Paid Users", value: "subscribers" },
                { label: "Total Users", value: "totalUsers" },
              ]}
              value={selectedOptions}
              onChange={(val) => setSelectedOptions(val)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#1890ff33",
                  primary: "#1890ff",
                },
              })}
            />
          </div>

          <div style={{ width: 200 }}>
            <Select
              isMulti={false}
              placeholder={"Select timeline..."}
              options={[
                { label: "Daily", value: "daily" },
                { label: "Weekly", value: "weekly" },
                { label: "Monthly", value: "monthly" },
              ]}
              value={chartTimeline}
              onChange={(val) => setChartTimeline(val)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#1890ff33",
                  primary: "#1890ff",
                },
              })}
            />
          </div>
        </div>
        <Chart
          data={chartData}
          selectedOptions={selectedOptions}
          timeline={chartTimeline}
        />
        <div style={{ marginTop: 40 }} />
      </div>

      <div
        style={{
          marginTop: 40,
          marginBottom: 40,
          padding: 40,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-evenly",
            marginBottom: 20,
          }}
        >
          <div style={{ width: "50%" }}>
            <Select
              defaultValue={providersList}
              isClearable={true}
              isSearchable={true}
              isMulti={true}
              placeholder={"Select providers..."}
              // name="color"
              options={providersList}
              value={selectedProviders}
              getOptionLabel={option => (option?.first_name || '') + ' ' + (option?.last_name || '')}
              getOptionValue={option => option.firebase_id}
              onChange={(val) => {
                console.log('selected providers: ', val)
                setSelectedProviders(val)
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#1890ff33",
                  primary: "#1890ff",
                },
              })}
            />
          </div>

          <div style={{ width: 210 }}>
            <Select
              isMulti={false}
              placeholder={"Select timeline..."}
              options={[
                { label: "Daily", value: "day" },
                { label: "Weekly", value: "week" },
                { label: "Monthly", value: "month" },
              ]}
              value={appChartTimeline}
              onChange={(val) => {
                setAppChartTimeline(val)
                // console.log('testing::: ' ,groupBy(appointmentList))
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#1890ff33",
                  primary: "#1890ff",
                },
              })}
            />
          </div>
          <div style={{ width: 210 }}>
            <Select
              isMulti={true}
              isClearable={true}
              isSearchable={true}
              defaultValue={appCategory}
              placeholder={"Select category..."}
              options={[
                { label: "Health Coach", value: "healthCoach" },
                { label: "All", value: "total_count" },
                { label: "Clinicians", value: "clinicians" },
              ]}
              value={appCategory}
              onChange={(val) => setAppCategory(val)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#1890ff33",
                  primary: "#1890ff",
                },
              })}
            />
          </div>
        </div>
        <AppointmentChart
          data={appointmentChartData}
          selectedOptions={selectedAppOptions}
          timeline={appChartTimeline}
        />
        <div style={{ marginTop: 40 }} />
      </div>
    </>
  );
}

const AppointmentChart = ({ data, selectedOptions, timeline }) => {
  const [chartData, setChartData] = useState([]);
  let width = window.innerWidth / 1.5;
  // console.log('testing: ', selectedOptions)
  const random_hex_color_code = (i) => {
    return "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);});
  };

  const formatXAxis = (tickItem) => {
    let formatString = 'll'
    switch (timeline.value) {
      case 'day':
        formatString = 'll'
        break;
      case 'week':
        formatString = `MMM, [wk ${Math.ceil(moment(tickItem).date() / 7)}], YY`
        break;
      case 'month':
        formatString = 'MMM, YY'
        break;
    
      default:
        break;
    }
    return moment(tickItem).format(formatString)
  }

  const getLines = () => {
    let lines =
      selectedOptions &&
      selectedOptions.map((x, i) => (
        <Line
          key={x.label}
          dot={false}
          name={x.label}
          type="monotone"
          dataKey={x.value}
          stroke={random_hex_color_code(x)}
        />
      ));
    return lines;
  };

  return (
    <LineChart
      width={width}
      height={300}
      data={data}
      margin={{ top: 5, right: 40, bottom: 5, left: 0 }}
    >
      <XAxis padding={{ top: 20 }} dataKey="date" type={"category"} tickFormatter={formatXAxis}/>
      <YAxis />
      {getLines()}
      <Tooltip labelFormatter={formatXAxis} />
      <Legend
        verticalAlign={"top"}
        align={"center"}
        height={40}
        wrapperStyle={{ left: 40 }}
      />
    </LineChart>
  );
};
const Chart = ({ data, selectedOptions, timeline }) => {
  const [chartData, setChartData] = useState([]);
  // console.log('chartData: ', chartData)
  let width = window.innerWidth / 1.5;

  const random_hex_color_code = (i) => {
    switch (i.value) {
      case "activeUsers":
        return "green";
      case "newUsers":
        return "red";
      case "totalUsers":
        return "black";
      default:
        return "blue";
    }
  };

  useEffect(() => {
    if (!data || data.length === 0) return;

    switch (timeline.value) {
      case "daily":
        setChartData(data);
        break;
      case "weekly":
        setChartData(getWeeklyData(data));
        break;
      case "monthly":
        setChartData(getMonthlyData(data));
        break;
      default:
        setChartData(data);
    }
  }, [data, timeline]);

  const getWeeklyData = (data) => {
    console.log("getting weekly data");
    let finalObject = {};
    for (let i = data.length - 1; i >= 0; i--) {
      let entry = data[i];
      let date = moment.utc(entry.fullDate);
      let startOfWeek = moment.utc(entry.fullDate).startOf("week");
      let endOfWeek = moment.utc(entry.fullDate).endOf("week");

      //it falls within the week from the corresponding sunday, add it
      let temp = finalObject[startOfWeek.format("D-MMM-YY")] || {
        newUsers: 0,
        subscribers: 0,
        activeUsers: 0,
        totalUsers: 0,
      };
      temp["newUsers"] += entry.newUsers;
      temp["subscribers"] += entry.subscribers;
      temp["activeUsers"] += entry.activeUsers;
      temp["totalUsers"] = entry.totalUsers;
      finalObject[startOfWeek.format("D-MMM-YY")] = temp;
    }

    let toReturn = [];
    if (!finalObject || Object.keys(finalObject).length === 0) return [];

    Object.keys(finalObject).forEach((date, index) => {
      let temp = finalObject[date];
      temp.date = date;
      toReturn.unshift(temp);
    });

    for (let i = toReturn.length - 1; i >= 1; i--) {
      let thisWeek = toReturn[i].newUsers || 0;
      let lastWeek = toReturn[i - 1].newUsers || 0;
      if (lastWeek && lastWeek > 0) {
        toReturn[i].change = `${Math.round(
          ((thisWeek - lastWeek) / lastWeek) * 100
        )}%`;
      } else {
        toReturn[i].change = `${thisWeek * 100}%`;
      }
    }
    return toReturn;
  };

  const getMonthlyData = (data) => {
    console.log("getting monthly data");
    let finalObject = {};
    let currentMonth = moment.utc().startOf("month");
    let index = 1;
    for (let i = data.length - 1; i >= 0; i--) {
      let entry = data[i];
      let date = moment.utc(entry.fullDate);
      if (date.isSame(currentMonth, "month")) {
        //it falls within the same month
        let temp = finalObject[currentMonth.format("D-MMM-YY")] || {
          newUsers: 0,
          subscribers: 0,
          activeUsers: 0,
          totalUsers: 0,
        };
        temp["newUsers"] += entry.newUsers;
        temp["subscribers"] += entry.subscribers;
        temp["activeUsers"] += entry.activeUsers;
        temp["totalUsers"] = entry.totalUsers;
        finalObject[currentMonth.format("D-MMM-YY")] = temp;
      } else {
        currentMonth = currentMonth.subtract(1, "month");
        let temp = finalObject[currentMonth.format("D-MMM-YY")] || {
          newUsers: 0,
          subscribers: 0,
          totalUsers: 0,
        };
        temp["newUsers"] += entry.newUsers;
        temp["subscribers"] += entry.subscribers;
        temp["activeUsers"] += entry.activeUsers;
        temp["totalUsers"] = entry.totalUsers;

        finalObject[currentMonth.format("D-MMM-YY")] = temp;
      }
    }

    let toReturn = [];
    if (!finalObject || Object.keys(finalObject).length === 0) return [];

    Object.keys(finalObject).forEach((date) => {
      let temp = finalObject[date];
      temp.date = date;
      toReturn.unshift(temp);
    });

    for (let i = toReturn.length - 1; i >= 1; i--) {
      let thisMonth = toReturn[i].newUsers || 0;
      let lastMonth = toReturn[i - 1].newUsers || 0;
      if (lastMonth && lastMonth > 0) {
        toReturn[i].change = `${Math.round(
          ((thisMonth - lastMonth) / lastMonth) * 100
        )}%`;
      } else {
        toReturn[i].change = `${thisMonth * 100}%`;
      }
    }

    return toReturn;
  };

  const getLines = () => {
    let lines =
      selectedOptions &&
      selectedOptions.map((x, i) => (
        <Line
          key={x.label}
          dot={false}
          name={x.label}
          type="monotone"
          dataKey={x.value}
          stroke={random_hex_color_code(x)}
        />
      ));
    return lines;
  };

  return (
    <LineChart
      width={width}
      height={300}
      data={chartData}
      margin={{ top: 5, right: 40, bottom: 5, left: 0 }}
    >
      <XAxis 
        padding={{ top: 20 }} 
        dataKey="date" 
        type={"category"} 
        domain={[moment().format, "dataMax"]}
      />
      <YAxis />
      {getLines()}
      {selectedOptions &&
      selectedOptions.length > 0 &&
      (timeline.value === "weekly" || timeline.value === "monthly") ? (
        <Tooltip
          content={({ active, payload, label }) => {
            let date = moment(label).format("D-MMM-YY");
            return (
              <div style={{ padding: "10px 20px", backgroundColor: "#ffffdf" }}>
                <p>{date}</p>
                {payload && payload.length > 0 && payload[0].payload ? (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{ fontWeight: "bold" }}>
                      New Users{" "}
                      <span style={{ fontWeight: "normal", marginLeft: 5 }}>
                        {payload[0].payload.newUsers}
                      </span>
                    </p>
                    <p style={{ fontWeight: "bold" }}>
                      Paid Users{" "}
                      <span style={{ fontWeight: "normal", marginLeft: 5 }}>
                        {payload[0].payload.subscribers}
                      </span>
                    </p>
                    <p style={{ fontWeight: "bold" }}>
                      Total Users{" "}
                      <span style={{ fontWeight: "normal", marginLeft: 5 }}>
                        {payload[0].payload.totalUsers}
                      </span>
                    </p>
                    <p style={{ fontWeight: "bold" }}>
                      {`${timeline.value === "monthly" ? "MoM" : "WoW"} Growth`}{" "}
                      <span style={{ fontWeight: "normal", marginLeft: 5 }}>
                        {payload[0].payload.change}
                      </span>
                    </p>
                  </div>
                ) : null}
              </div>
            );
          }}
        />
      ) : (
        <Tooltip />
      )}
      <Legend
        verticalAlign={"top"}
        align={"center"}
        height={40}
        wrapperStyle={{ left: 40 }}
      />
    </LineChart>
  );
};
