import React, {useEffect, useState} from 'react'
import { useAuth } from '../../context/AuthContext';
import moment from 'moment';
import Select from "react-select";
import { getAllEvents } from '../../api/users';
import {
  Button,
  Tabs,
  Table,
  Tag,
  Space,
  Row,
  Col
} from 'antd';
import { eventsColumn } from '../../components/Financials/financialColumn';
import Loading from '../../components/Loading';
import axios from 'axios';
import {
  ReferenceLine,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

let financeData = [
  {
    "month": "Jan",
    "payment": {
      "ailaPC": 0,
      "ailaInc": 45,
      "total": 45
    }
  },
  {
    "month": "Feb",
    "payment": {
      "ailaPC": 574,
      "ailaInc": 172.29,
      "total": 746
    }
  },
  {
    "month": "Mar",
    "payment": {
      "ailaPC": 980.54,
      "ailaInc": 372.78,
      "total": 1354
    }
  },
  {
    "month": "Apr",
    "payment": {
      "ailaPC": 1496.4,
      "ailaInc": 1259.84,
      "ailaClaimed": 77.55,
      "total": 2834
    }
  },
  {
    "month": "May",
    "payment": {
      "ailaPC": 890.45,
      "ailaInc": 1482.68,
      "ailaClaimed": 77.55,
      "total": 2451
    }
  },
  {
    "month": "Jun",
    "payment": {
      "ailaPC": 284,
      "ailaInc": 836,
      "ailaClaimed": 155,
      "total": 1275
    }
  },
]

export default function Financials() {
  const { token, currentUser, getAuthToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [financialData, setFinancialData] = useState([]);
  console.log('data: ', financialData)
  const [finCategory, setFinCategory] = useState([
    { label: "Total Revenue", value: "total" },
    { label: "PC Revenue", value: "pc_inc" },
    { label: "Inc Revenue", value: "aila_inc" },
  ]);

  useEffect(() => {
    async function fetchAllPayments() {
      if(!token)
        await getAuthToken(currentUser);
      getPaymentList()
    }
    fetchAllPayments()
  }, [])

  const getPaymentList = () => {
    setLoading(true);
    let config = {
      method: 'get',
      url: process.env.REACT_APP_BACKEND_URL + 'stripe/payment-list',
      headers: {x_firebase_id: currentUser.uid, Authorization: 'JWT ' + token}
    }
    axios(config)
    .then(({data}) => {
        console.log("TESTING: ", groupBy(data))
        let tempArr = groupBy(data)
        tempArr.sort((a,b)=>new Date(a.date) - new Date(b.date));
        setFinancialData(tempArr)
        setLoading(false)
    }).catch(err => {
        setLoading(false)
        console.log('error when fetching payment list', err)
    })
  }
  

  const groupBy = (elements) => {
    const formatted = elements.map(elem => {
      return { created: moment.unix(elem.created).startOf('month').format('YYYY-MM-DD'), total: 0, data: elem }
    })
    // console.log('formated dates: ', formatted)
    const dates = formatted.map(elem => elem.created)
    // console.log('dates: ', dates)
    const uniqueDates = dates.filter((date, index) => dates.indexOf(date) === index)
    // console.log('unique dates: ', uniqueDates)
    return uniqueDates.map(date => {

      const total = formatted.filter(elem => elem.created === date).reduce((total, elem) => total + elem.data.payment_received, 0)
      const pc_inc = formatted.filter(elem => 
        elem.created === date && 
        elem.data.transfer_data?.destination  // transferred to PC inc if destination is present
      ).reduce((total, elem) => total + elem.data.payment_received, 0)
      const aila_inc = formatted.filter(elem => 
        elem.created === date && 
        (!elem.data.transfer_data?.destination)
      ).reduce((total, elem) => total + elem.data.payment_received, 0)
      let allAccessCondn = ['all access', 'premium']
      const all_access = formatted.filter(elem => {
        return elem.created === date && 
        allAccessCondn.some(el => elem.data.lines?.data?.[0]?.description?.toLowerCase()?.includes(el))
      }
      ).reduce((total, elem) => total + elem.data.payment_received, 0)
      let membershipCondn = ['membership']
      const membership = formatted.filter(elem => 
        elem.created === date && 
        membershipCondn.some(el => elem.data.lines?.data?.[0]?.description?.toLowerCase()?.includes(el))
      ).reduce((total, elem) => total + elem.data.payment_received, 0)
      const appointment = formatted.filter(elem => 
        elem.created === date && 
        elem.data.metadata?.appointment_invoice
      ).reduce((total, elem) => total + elem.data.payment_received, 0)

      let count_of_appointments = { 
        date, 
        total: total.toFixed(2), 
        aila_inc: aila_inc.toFixed(2), 
        pc_inc: pc_inc.toFixed(2), 
        all_access: all_access.toFixed(2), 
        membership: membership.toFixed(2), 
        appointment: appointment.toFixed(2),
      }
      // console.log('testing: ', count_of_appointments)
      return count_of_appointments
    })
  }

  const random_hex_color_code = (i) => {
    return "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);});
  };

  const formatXAxis = (tickItem) => {
    let formatString = 'll'
    switch ('month') {
      case 'day':
        formatString = 'll'
        break;
      case 'week':
        formatString = `MMM, [wk ${Math.ceil(moment(tickItem).date() / 7)}], YY`
        break;
      case 'month':
        formatString = 'MMM, YY'
        break;
    
      default:
        break;
    }
    return moment(tickItem).format(formatString)
  }

  if (loading) {
    return (
      <Loading/>
    )
  }

  return (
    <div>
      <div
        style={{
          marginTop: 40,
          marginBottom: 40,
          padding: 40,
          // backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: window.innerWidth / 1.5 }}>
          <Select
            isMulti={true}
            isClearable={true}
            isSearchable={true}
            defaultValue={finCategory}
            placeholder={"Select category..."}
            options={[
              { label: "Total Revenue", value: "total" },
              { label: "PC Revenue", value: "pc_inc" },
              { label: "Inc Revenue", value: "aila_inc" },
              { label: "All Access Membership", value: "all_access" },
              { label: "Coaching Membership", value: "membership" },
              { label: "Appointment Revenue", value: "appointment" },
            ]}
            value={finCategory}
            onChange={(val) => setFinCategory(val)}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#1890ff33",
                primary: "#1890ff",
              },
            })}
          />
        </div>
      </div>
        <LineChart
          width={window.innerWidth / 1.5}
          height={300}
          data={financialData}
          margin={{ top: 5, right: 40, bottom: 5, left: 0 }}
        >
          {
            finCategory.map((rev, index) => (
              <Line
                key={rev.label}
                dot={false}
                name={rev.label}
                type="monotone"
                dataKey={rev.value}
                stroke={random_hex_color_code(index)}
              />
            ))
          }
          {/* <CartesianGrid stroke="#ccc" strokeDasharray="5 5" /> */}
          <XAxis padding={{ top: 20 }} dataKey="date" type={"category"} tickFormatter={formatXAxis} />
          <YAxis domain={[0, 4000]}/>
          <Tooltip
          // content={({ active, payload, label }) => {
          //   return (
          //     <div style={{ padding: "10px 20px", backgroundColor: "#ffffdf" }}>
          //       <p>{label}</p>
          //       {payload && payload.length > 0 && payload[0].payload ? (
          //         <div style={{ display: "flex", flexDirection: "column" }}>
          //           <p style={{ fontWeight: "bold" }}>
          //             Total Revenue:{" $"}
          //             <span style={{ fontWeight: "normal", marginLeft: 5 }}>
          //               {payload[0].payload.payment?.total || 0}
          //             </span>
          //           </p>
          //           <p style={{ fontWeight: "bold" }}>
          //             Inc Revenue:{" $"}
          //             <span style={{ fontWeight: "normal", marginLeft: 5 }}>
          //               {payload[0].payload.payment?.ailaInc || 0}
          //             </span>
          //           </p>                   
          //           <p style={{ fontWeight: "bold" }}>
          //             PC Revenue:{" $"}
          //             <span style={{ fontWeight: "normal", marginLeft: 5 }}>
          //               {payload[0].payload.payment?.ailaPC || 0}
          //             </span>
          //           </p>
          //         </div>
          //       ) : null}
          //     </div>
          //   );
          // }}
        />
        </LineChart>

      {/* <Table columns={eventsColumn} dataSource={financialData} bordered /> */}
    </div>
  )
}
